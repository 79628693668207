import React from 'react'
import { MoreProjects, InfoSection } from '../../components'
import { homePageObjects } from '../../projectObjects'
import { animationOne, transition } from '../../components/Animations'
import { Container } from '../../globalStyles'

function Home() {
  return (
    <>
      <Container
        initial='out'
        animate='in'
        exit='out'
        variants={animationOne}
        transition={transition}>
        {homePageObjects.map((project) => (
          <InfoSection key={project.id} project={project} homePageButton />
        ))}
        <MoreProjects />
      </Container>
    </>
  )
}

export default Home

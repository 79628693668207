import React from 'react'
import { ContactForm } from '../../components'
import { animationThree, transition } from '../../components/Animations'
import { Container } from '../../globalStyles'

function Contacts() {
  return (
    <Container
      initial='out'
      animate='in'
      exit='out'
      variants={animationThree}
      transition={transition}>
      <ContactForm />
    </Container>
  )
}

export default Contacts

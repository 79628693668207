import React, { useCallback, useEffect, useRef } from 'react'
import { animationTwo, transition } from '../Animations/index'
import {
  Backdrop,
  ModalWrapper,
  ModalContent,
  CloseModalIcon,
  ReactPlayerDiv,
  DetailsContent,
  ProjectName,
  AboutTheProject,
  AboutTheProjectDetails,
  Dependencies,
  PackageName,
} from './Modal.elements'

import ReactPlayer from 'react-player'

function Modal({ project, showModal, setShowModal, playDemo, showDetails }) {
  const modalRef = useRef()

  // For Clicking on any place to close the modal
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false)
    }
  }

  // For Pressing the Esc key to close the modal
  const escKeyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false)
      }
    },
    [setShowModal, showModal]
  )
  // Call the useCallback function
  useEffect(() => {
    document.addEventListener('keydown', escKeyPress)
    return () => document.removeEventListener('keydown', escKeyPress)
  }, [escKeyPress])

  return (
    <>
      {showModal && showDetails && (
        <Backdrop ref={modalRef} onClick={closeModal}>
          <ModalWrapper
            showModal
            initial='out'
            animate='in'
            exit='out'
            variants={animationTwo}
            transition={transition}>
            <ModalContent>
              <DetailsContent>
                <ProjectName>{project.name}</ProjectName>

                <AboutTheProject>About The Project</AboutTheProject>
                <AboutTheProjectDetails>
                  {project.aboutTheProject}
                </AboutTheProjectDetails>

                <Dependencies>Dependencies</Dependencies>
                <PackageName>{project.packagesUsed}</PackageName>
              </DetailsContent>
            </ModalContent>
            <CloseModalIcon onClick={() => setShowModal((prev) => !prev)} />
          </ModalWrapper>
        </Backdrop>
      )}

      {showModal && playDemo && (
        <Backdrop ref={modalRef} onClick={closeModal}>
          <ModalWrapper showModal={showModal}>
            <ModalContent>
              <h1>{project.name}</h1>
              <h2>Coming Soon</h2>
              <ReactPlayerDiv>
                <ReactPlayer
                  url={project.videoUrl}
                  style={{
                    top: '100',
                    left: '100',
                    position: 'absolute',
                  }}
                  height='80%'
                  width='80%'
                />
              </ReactPlayerDiv>
            </ModalContent>
            <CloseModalIcon onClick={() => setShowModal((prev) => !prev)} />
          </ModalWrapper>
        </Backdrop>
      )}
    </>
  )
}

export default Modal

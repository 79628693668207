import React from 'react'
import styled from 'styled-components'

function Project({ project: { title, description, image, id, path, demo } }) {
  return (
    <Container>
      <div>{title}</div>
      <Image src={image} alt='title' />
    </Container>
  )
}

export default Project

const Container = styled.div`
  display: flex;
  flex: 1 1 320px;
  // Mobile friendly by default
  flex-direction: column;
  align-items: center;
  border: 1px solid gray;
  box-shadow: 5px 5px #ccc;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  /* &:hover {
    padding: 50px;
  } */

  // Switch to rows on large devices
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`

const Image = styled.img`
  display: flex;
  object-fit: contain;
  width: 300px;
  height: 300px;
`

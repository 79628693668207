import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { animationOne, transition } from '../../components/Animations'
import { Button } from '../../globalStyles'
import {
  Contacts,
  Contact,
  ContactsContainer,
  FrontEndDeveloper,
  Name,
  ResumeContainer,
  ResumeLeftContents,
  ResumeRightContents,
  Title,
  Explanation,
  SubTitle,
  Skills,
  Skill,
  Experience,
  PersonalProjectsContainer,
  PersonalProjectsSubtitle,
  PersonalProject,
  PersonalProjects,
  PersonalProjectsContent,
  PersonalProjectsCompleted,
  ButtonDiv,
  Link,
  AboutMeContainer,
  ExperienceSkill,
} from './Resume.elements'

function Resume() {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({ content: () => componentRef.current })

  return (
    <>
      <ResumeContainer
        ref={componentRef}
        initial='out'
        animate='in'
        exit='out'
        variants={animationOne}
        transition={transition}>
        <ResumeLeftContents>
          <Name>ZRRAWAR DZIRMAL</Name>
          <FrontEndDeveloper>FRONT-END ENGINEER</FrontEndDeveloper>
          <ContactsContainer>
            <Contacts>
              <Contact>www.dzirmal.com </Contact>
              <Contact>dzirmal@gmail.com </Contact>
              <Contact>(619) 708-8571</Contact>
            </Contacts>
          </ContactsContainer>

          <AboutMeContainer>
            <Title>ABOUT ME</Title>
            <Explanation>
              I'm a front-end engineer with 4 years' experience. I like to
              organize my stuff. I am able to work with a team or independently.
              I love to develop a website & that is why I changed my major.
            </Explanation>
          </AboutMeContainer>

          <Title>DEVELOPER SKILLS</Title>
          <SubTitle>RELEVANT SKILLS</SubTitle>
          <Skills>
            <Skill>HTML5</Skill>
            <Skill>CSS</Skill>
            <Skill>JavaScript</Skill>
            <Skill>ReactJS</Skill>
            <Skill>React API Context</Skill>
            <Skill>Redux</Skill>
            <Skill>Mobx</Skill>
            <Skill>Axios</Skill>
            <Skill>Stripe</Skill>
            <Skill>Postman</Skill>
            <Skill>Express</Skill>
            <Skill>Firebase</Skill>
            <Skill>Styled-Components</Skill>
            <Skill>Material-UI</Skill>
            <Skill>React-To-Print</Skill>
            <Skill>Version Control: Git</Skill>
          </Skills>

          <SubTitle>PERSONAL SKILLS</SubTitle>
          <Skills>
            <Skill>Team & independent worker</Skill>
            <Skill>Punctual</Skill>
            <Skill>
              Highly organized and able to manage multiple activities and
              priorities
            </Skill>
            <Skill>Flexible and adaptable in a fast-paced environment</Skill>
            <Skill>Easily coachable and willing to learn</Skill>
            <Skill>Realizer own mistakes</Skill>
            <Skill>Humble about what I don't know</Skill>
            <Skill>Happily accept logical advices</Skill>
          </Skills>

          <Title>EDUCATION</Title>
          <Explanation>
            Bachelor of Management | 2007-2011 | Volgograd State Technical
            University (VolgGTU) in Russia
          </Explanation>
          <Explanation>Minor: Company economist</Explanation>
        </ResumeLeftContents>

        <ResumeRightContents>
          <Title>EXPERIENCE</Title>
          <SubTitle>FRONT-END ENGINEER</SubTitle>
          <Experience>
            <Skill> 03/2017-12/2020 </Skill>
            <Skill>Sheek Media</Skill>
          </Experience>

          <ExperienceSkill>
            I ensured the alignment of design, UX and UI
          </ExperienceSkill>
          <ExperienceSkill>Built reusable code for future use</ExperienceSkill>
          <ExperienceSkill>
            Optimized for maximum speed and scalability
          </ExperienceSkill>
          <ExperienceSkill>
            I built code to consume the RESTful APIs and third-party web
            services.
          </ExperienceSkill>
          <ExperienceSkill>
            Weekly reported directly to lead developer
          </ExperienceSkill>
          <ExperienceSkill>
            Participated in daily stand-ups with the team
          </ExperienceSkill>
          <ExperienceSkill>
            Work with the team to transform the web application from React class
            components to React Hooks
          </ExperienceSkill>
          <ExperienceSkill>
            Tested and debugged to ensure flawless functionality & performance
            of the code
          </ExperienceSkill>
          <ExperienceSkill>
            Researched and solved problems using internet search, vendor
            support,and other external resources
          </ExperienceSkill>

          <SubTitle>PERSONAL PROJECTS</SubTitle>
          <ExperienceSkill>
            All my projects are created in the most latest React, JavaScript,
            firebase features.
          </ExperienceSkill>

          <Link href='https://www.dzirmal.com' target='_blank' rel='noreferrer'>
            www.dzirmal.com
          </Link>

          <PersonalProjectsContainer>
            <PersonalProjectsCompleted>
              <PersonalProjectsContent>
                <PersonalProjectsSubtitle>E-COMMERCE</PersonalProjectsSubtitle>
                <PersonalProjects>
                  <PersonalProject>Amazon-clone</PersonalProject>
                </PersonalProjects>
              </PersonalProjectsContent>

              <PersonalProjectsContent>
                <PersonalProjectsSubtitle>
                  SOCIAL MEDIA
                </PersonalProjectsSubtitle>
                <PersonalProjects>
                  <PersonalProject>Facebook-clone</PersonalProject>
                  <PersonalProject>Instagram-clone</PersonalProject>
                </PersonalProjects>
              </PersonalProjectsContent>

              <PersonalProjectsContent>
                <PersonalProjectsSubtitle>MOVIES</PersonalProjectsSubtitle>
                <PersonalProjects>
                  <PersonalProject>Netflix-clone</PersonalProject>
                </PersonalProjects>
              </PersonalProjectsContent>

              <PersonalProjectsContent>
                <PersonalProjectsSubtitle>VIDEOS</PersonalProjectsSubtitle>
                <PersonalProjects>
                  <PersonalProject>YouTube-clone</PersonalProject>
                </PersonalProjects>
              </PersonalProjectsContent>

              <PersonalProjectsContent>
                <PersonalProjectsSubtitle>REST-API</PersonalProjectsSubtitle>
                <PersonalProjects>
                  <PersonalProject>Star Wars Movies</PersonalProject>
                </PersonalProjects>
              </PersonalProjectsContent>

              <PersonalProjectsContent>
                <PersonalProjectsSubtitle>
                  SEARCH ENGINE
                </PersonalProjectsSubtitle>
                <PersonalProjects>
                  <PersonalProject>Google-clone</PersonalProject>
                </PersonalProjects>
              </PersonalProjectsContent>

              <PersonalProjectsContent>
                <PersonalProjectsSubtitle>DONE LIST</PersonalProjectsSubtitle>
                <PersonalProjects>
                  <PersonalProject>Todo List</PersonalProject>
                </PersonalProjects>
              </PersonalProjectsContent>
            </PersonalProjectsCompleted>
          </PersonalProjectsContainer>

          <SubTitle>POLYGLOT</SubTitle>
          <Skills>
            <Skill>English</Skill>
            <Skill>Russian</Skill>
            <Skill>Farsi/Persian </Skill>
            <Skill>Pashto</Skill>
            <Skill>Dari</Skill>
          </Skills>
        </ResumeRightContents>
      </ResumeContainer>
      <ButtonDiv>
        <Button fontBig primary big onClick={handlePrint}>
          PRINT RESUME
        </Button>
      </ButtonDiv>
    </>
  )
}

export default Resume

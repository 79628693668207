import { useRef } from 'react'
import {
  ButtonIcon,
  ContactFormContainer,
  ContactFormDiv,
  Form,
  FormButton,
  FormIcon,
  H1,
  Input,
  Label,
  TextArea,
} from './ContactForm.elements'
import { FiMail, FiSend } from 'react-icons/fi'

import emailjs from 'emailjs-com'
import { init } from 'emailjs-com'
init('user_oHS0zfPL3E6Fqlr4p0jj8')

function ContactForm() {
  const inputRef = useRef(null)

  const sendEmail = (e) => {
    e.preventDefault()
    inputRef.current.focus()

    emailjs
      .sendForm(
        'service_c7s97z1',
        'template_3wnxpic',
        e.target,
        'user_oHS0zfPL3E6Fqlr4p0jj8'
      )
      .then(
        (result) => {
          console.log(result.text)
        },
        (error) => {
          console.log(error.text)
        }
      )

    e.target.reset()
  }

  return (
    <ContactFormContainer onSubmit={sendEmail}>
      <ContactFormDiv>
        <H1>
          <FormIcon>
            <FiMail />
          </FormIcon>
          Send email
        </H1>
        <Form>
          <Label>Your Name</Label>
          <Input
            required
            placeholder='Your Name'
            type='text'
            ref={inputRef}
            name='name'
          />
          <Label>Your E-mail</Label>
          <Input required placeholder='Your E-mail' type='email' name='email' />
          <Label>Subject</Label>
          <Input
            required
            placeholder='The Subject'
            type='text'
            name='subject'
          />
          <Label>Message</Label>
          <TextArea
            required
            placeholder='Your Message'
            type='text'
            name='message'
          />
          <FormButton>
            <ButtonIcon>
              <FiSend />
            </ButtonIcon>
            SEND
          </FormButton>
        </Form>
      </ContactFormDiv>
    </ContactFormContainer>
  )
}

export default ContactForm

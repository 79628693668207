import React from 'react'
import { InfoSection } from '../../components'
import { animationThree, transition } from '../../components/Animations'
import { Container } from '../../globalStyles'
import { aboutMe } from './aboutMeObjects'

function About() {
  return (
    <>
      <Container
        initial='out'
        animate='end'
        exit='out'
        variants={animationThree}
        transition={transition}>
        {aboutMe.map((project, i) => (
          <InfoSection key={i} project={project} />
        ))}
      </Container>
    </>
  )
}

export default About

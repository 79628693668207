import React, { useState } from 'react'
import { DropdownContainer, Li, Ul, Links } from './Dropdown.elements'

function Dropdown() {
  const [click, setClick] = useState(false)

  const projects = [
    'Amazon Clone',
    'Netflix Clone',
    'YouTube Clone',
    'Facebook Clone',
    'Instagram Clone',
    'Google Clone',
    'Star Wars APIs',
    'Todo List',
  ]

  const handleClick = (e) => {
    e.preventDefault()
    setClick(!click)
  }
  return (
    <DropdownContainer>
      <Ul className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}>
        {projects.map((project) => (
          <Li onClick={(e) => handleClick(e)} key={project}>
            <Links to='/projects' onClick={() => setClick(false)}>
              {project}
            </Links>
          </Li>
        ))}
      </Ul>
    </DropdownContainer>
  )
}

export default Dropdown

import React, { useState } from 'react'
import { InfoSection, Modal } from '../../components'
import { animationTwo, transition } from '../../components/Animations'
import { Container } from '../../globalStyles'

import { projects } from '../../projectObjects'

function Projects() {
  const [showModal, setShowModal] = useState(false)
  const [playDemo, setPlayDemo] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [project, setProject] = useState([])

  const openDetailsModal = (project) => {
    setShowModal(true)
    setShowDetails(true)
    setPlayDemo(false)
    setProject(project)
  }

  const openDemoModal = (project) => {
    setShowModal(true)
    setPlayDemo(true)
    setShowDetails(false)
    setProject(project)
  }

  return (
    <>
      <Container
        initial='out'
        animate='in'
        exit='out'
        variants={animationTwo}
        transition={transition}>
        {projects.map((project) => (
          <InfoSection
            key={project.id}
            project={project}
            openDetailsModal={() => openDetailsModal(project)}
            openDemoModal={() => openDemoModal(project)}
            projectsPageButton
          />
        ))}

        {showDetails && (
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            project={project}
            showDetails={showDetails}
          />
        )}

        {playDemo && (
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            project={project}
            playDemo={playDemo}
          />
        )}
      </Container>
    </>
  )
}

export default Projects

import React, { useState } from 'react'
import { Button, Links } from '../../globalStyles'
import {
  FooterContainer,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLink,
  FooterLinksItems,
  FooterSubHeading,
  FooterSubscription,
  FooterSubText,
  FooterLinkTitle,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcon,
  SocialIcons,
  WebsiteRights,
  SocialIconLink,
  FooterLinkTitleIcon,
  FooterALink,
} from './Footer.elements'

import {
  FaCopyright,
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa'
import { MdFindInPage, MdOndemandVideo } from 'react-icons/md'
import { GiAchievement } from 'react-icons/gi'

function Footer() {
  const [copyRight] = useState(new Date().getFullYear())

  return (
    <FooterContainer>
      <FooterSubscription>
        <FooterSubHeading>
          By giving me a chance you won't regret!
        </FooterSubHeading>

        <FooterSubText>You can send me an email at any time.</FooterSubText>

        <Links to='/contacts'>
          <Button fontBig>CONTACT ME</Button>
        </Links>
      </FooterSubscription>

      <FooterLinksContainer>
        <FooterLinksWrapper>
          <FooterLinksItems>
            <FooterLinkTitle>
              <FooterLinkTitleIcon>
                <MdFindInPage />
              </FooterLinkTitleIcon>
              Pages
            </FooterLinkTitle>
            <FooterLink to='/'>Home</FooterLink>
            <FooterLink to='/projects'>Projects</FooterLink>
            <FooterLink to='/about'>About Me</FooterLink>
            <FooterLink to='/contacts'>Contacts</FooterLink>
          </FooterLinksItems>
          <FooterLinksItems>
            <FooterLinkTitle>
              <FooterLinkTitleIcon>
                <GiAchievement />
              </FooterLinkTitleIcon>
              Achievements
            </FooterLinkTitle>
            <FooterLink to='/sign-up'>Amazon Clone</FooterLink>
            <FooterLink to='/sign-up'>Netflix Clone</FooterLink>
            <FooterLink to='/sign-up'>Facebook Clone</FooterLink>
            <FooterLink to='/sign-up'>Instagram Clone</FooterLink>
            <FooterLink to='/sign-up'>YouTube Clone</FooterLink>
            <FooterLink to='/sign-up'>Google Clone</FooterLink>
            <FooterLink to='/sign-up'>ToDo List</FooterLink>
          </FooterLinksItems>
        </FooterLinksWrapper>

        <FooterLinksWrapper>
          <FooterLinksItems>
            <FooterLinkTitle>
              <FooterLinkTitleIcon>
                <MdOndemandVideo />
              </FooterLinkTitleIcon>
              Demos
            </FooterLinkTitle>
            <FooterLink to='/sign-up'>Amazon Clone</FooterLink>
            <FooterLink to='/sign-up'>Netflix Clone</FooterLink>
            <FooterLink to='/sign-up'>Facebook Clone</FooterLink>
            <FooterLink to='/sign-up'>Instagram Clone</FooterLink>
            <FooterLink to='/sign-up'>YouTube Clone</FooterLink>
            <FooterLink to='/sign-up'>Google Clone</FooterLink>
            <FooterLink to='/sign-up'>ToDo List</FooterLink>
          </FooterLinksItems>
          <FooterLinksItems>
            <FooterLinkTitle>
              <FooterLinkTitleIcon>
                <FaGithub />
              </FooterLinkTitleIcon>
              Code Source
            </FooterLinkTitle>
            <FooterALink
              href='https://github.com/dzirmal/amz-e-commerce-full-clone'
              target='_blank'
              aria-label='github'>
              Amazon Clone
            </FooterALink>
            <FooterALink
              href='https://github.com/dzirmal/netflix-clone'
              target='_blank'
              aria-label='github'>
              Netflix Clone
            </FooterALink>
            <FooterALink
              href='https://github.com/dzirmal/Facebook-clone'
              target='_blank'
              aria-label='github'>
              Facebook Clone
            </FooterALink>
            <FooterALink
              href='https://github.com/dzirmal/Instagram-clone'
              target='_blank'
              aria-label='github'>
              Instagram Clone
            </FooterALink>
            <FooterALink
              href='https://github.com/dzirmal/YouTube-clone'
              target='_blank'
              aria-label='github'>
              YouTube Clone
            </FooterALink>
            <FooterALink
              href='https://github.com/dzirmal/google-clone'
              target='_blank'
              aria-label='github'>
              Google Clone
            </FooterALink>
            <FooterALink
              href='https://github.com/dzirmal/star-wars-API'
              target='_blank'
              aria-label='github'>
              Star Wars APIs
            </FooterALink>
            <FooterALink
              href='https://github.com/dzirmal/ToDo'
              target='_blank'
              aria-label='github'>
              ToDo List
            </FooterALink>
          </FooterLinksItems>
        </FooterLinksWrapper>
      </FooterLinksContainer>

      <SocialMedia>
        <SocialMediaWrap>
          <SocialLogo to='/'>
            <SocialIcon />
            ZRRAWAR DZIRMAL
          </SocialLogo>

          <WebsiteRights>
            ZRRAWAR DZIRMAL <FaCopyright /> {copyRight}
          </WebsiteRights>
          <SocialIcons>
            <SocialIconLink
              href='https://www.facebook.com/dzirmal'
              target='_blank'
              aria-label='Facebook'>
              <FaFacebook />
            </SocialIconLink>

            <SocialIconLink
              href='https://www.instagram.com/zrrawardzirmal/'
              target='_blank'
              aria-label='Instagram'>
              <FaInstagram />
            </SocialIconLink>

            <SocialIconLink
              href={'https://www.youtube.com/channel/UCvhZYzIFRcDAFrcvBrQclbw'}
              target='_blank'
              aria-label='YouTube'
              rel='noopener noreferrer'>
              <FaYoutube />
            </SocialIconLink>

            <SocialIconLink
              href='https://twitter.com/dzirmal'
              target='_blank'
              aria-label='Twitter'>
              <FaTwitter />
            </SocialIconLink>

            <SocialIconLink
              href='www.linkedin.com/in/zrrawar-dzirmal'
              target='_blank'
              aria-label='LinkedIn'>
              <FaLinkedin />
            </SocialIconLink>
          </SocialIcons>
        </SocialMediaWrap>
      </SocialMedia>
    </FooterContainer>
  )
}

export default Footer

const size = {
  mobileXS: '280px',
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '414px',
  mobileXL: '480px',
  tabletS: '600px',
  tabletM: '768px',
  tabletL: '990px',
  tabletXL: '1280px',
  laptopS: '1200px',
  laptopM: '1366px',
  laptopL: '1600px',
  desktopS: '1700px',
  desktopM: '1920px',
  desktopL: '2880px',
  desktopXL: '4096px',
}

export const devices = {
  mobileXS: `(max-width: ${size.mobileXS})`,
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileXL: `(max-width: ${size.mobileXL})`,
  tabletS: `(max-width: ${size.tabletS})`,
  tabletM: `(max-width: ${size.tabletM})`,
  tabletL: `(max-width: ${size.tabletL})`,
  tabletXL: `(max-width: ${size.tabletXL})`,
  laptopS: `(max-width: ${size.laptopS})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktopS: `(max-width: ${size.desktopS})`,
  desktopM: `(max-width: ${size.desktopM})`,
  desktopL: `(max-width: ${size.desktopL})`,
  desktopXL: `(max-width: ${size.desktopXL})`,
}

import { Switch, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import GlobalStyle from './globalStyles'
import { Footer, Header } from './components'
import Home from './Pages/Home/Home'
import ProjectHome from './Pages/Projects/Projects'
import About from './Pages/About/About'
import Contacts from './Pages/Contacts/Contacts'
import Resume from './Pages/Resume/Resume'
import ScrollToTop from './components/ScrollToTop'

function App() {
  let location = useLocation()
  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <Header />
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route exact path='/about' component={About} />
          <Route exact path='/contacts' component={Contacts} />
          <Route exact path='/resume' component={Resume} />
          <Route exact path='/projects' component={ProjectHome} />
          <Route exact path='/' component={Home} />
        </Switch>
      </AnimatePresence>
      <Footer />
    </>
  )
}

export default App

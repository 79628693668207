import React from 'react'
import { Button, Container, Links } from '../../globalStyles'
import {
  Heading,
  Headline,
  HeadLineIcon,
  Img,
  ImgWrapper,
  InfoColumn,
  InfoRow,
  InfoSec,
  SubTitle,
  TextWrapper,
  TopLine,
  ButtonDiv,
  ButtonSpan,
} from './InfoSection.elements'

function InfoSection({
  openDemoModal,
  openDetailsModal,
  projectsPageButton,
  homePageButton,
  project: {
    primary,
    lightBg,
    imgStart,
    lightTopLine,
    lightText,
    headLine,
    lightTextDesc,
    buttonLabel,
    description,
    topLine,
    start,
    img,
    alt,
    buttonLink,
    icon,
    style,
    buttonLabelVideo,
    buttonLabelDetails,
  },
}) {
  return (
    <InfoSec lightBg={lightBg}>
      <Container>
        <InfoRow imgStart={imgStart}>
          <InfoColumn>
            <TextWrapper>
              <TopLine lightTopLine={lightTopLine}>{topLine}</TopLine>
              <Heading lightText={lightText}>
                <HeadLineIcon>{icon}</HeadLineIcon>
                <Headline style={style}>{headLine}</Headline>
              </Heading>
              <SubTitle lightTextDesc={lightTextDesc}>{description}</SubTitle>
            </TextWrapper>
          </InfoColumn>
          <InfoColumn>
            <ImgWrapper start={start}>
              <Img src={img} alt={alt} />
            </ImgWrapper>
          </InfoColumn>
        </InfoRow>

        <ButtonDiv>
          {projectsPageButton && (
            <Button big primary onClick={openDetailsModal}>
              {buttonLabelDetails}
            </Button>
          )}

          {/* {projectsPageButton && (
            <Button big fontBig primary onClick={openDemoModal}>
              {buttonLabelVideo}
            </Button>
          )} */}
        </ButtonDiv>

        <Links to={buttonLink}>
          {homePageButton && (
            <Button big fontBig primary>
              <ButtonSpan>{buttonLabel}</ButtonSpan>
            </Button>
          )}
        </Links>
      </Container>
    </InfoSec>
  )
}

export default InfoSection

import styled from 'styled-components'
import { devices } from '../../device'

export const InfoSec = styled.div`
  width: 100%;
  color: #fff;
  padding: 160px;
  background: ${({ lightBg }) => (lightBg ? 'rgb(234, 237, 237)' : '#101522')};

  @media ${devices.laptopM} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }
`

export const InfoRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: ${({ imgStart }) => (imgStart ? 'row-reverse' : 'row')};
  @media ${devices.tabletM} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const InfoColumn = styled.div`
  margin-bottom: 15px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
  max-width: 50%;
  flex-basis: 50%;

  @media ${devices.tabletM} {
    width: 100%;
    flex-basis: 100%;
    display: flex;
    justify-content: center;
  }
`

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
`

export const TopLine = styled.div`
  color: ${({ lightTopLine }) => (lightTopLine ? '#0BD5EA' : '#1c2237')};
  font-size: clamp(1rem, 6vw, 1.2rem);
  line-height: 16px;
  letter-spacing: 1.4px;
  margin-bottom: 16px;
`

export const TopLineIcon = styled.div`
  margin-right: 10px;
`

export const Heading = styled.h1`
  display: flex;
  margin-bottom: 24px;
  font-size: clamp(2rem, 8vw, 4rem);
  line-height: 1.1;
  color: ${({ lightText }) => (lightText ? '#f7f8fa' : '#1c2237')};
`

export const HeadLineIcon = styled.span``

export const Headline = styled.div``

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: start;

  @media ${devices.tabletM} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
`

export const SubTitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: clamp(1rem, 6vw, 1.5rem);
  line-height: 24px;
  color: ${({ lightTextDesc }) => (lightTextDesc ? '#a9b3c1' : '#1c2237')};
`

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: ${({ start }) => (start ? 'flex-start' : 'flex-end')};
  min-width: 260px;

  @media ${devices.tabletM} {
    padding-bottom: 10px;
  }
`

export const Img = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  max-height: 500px;
  vertical-align: middle;
  display: inline-block;
`

export const ButtonSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`

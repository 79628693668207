import React from 'react'
import { IconContext } from 'react-icons/lib'
// import { GiCrystalBars, GiCutDiamond, GiRock } from 'react-icons/gi'
import { FaAmazon, FaFacebookSquare } from 'react-icons/fa'
import { SiNetflix } from 'react-icons/si'
import { Button, Links } from '../../globalStyles'
import {
  BreakLine,
  ProjectsCard,
  ProjectsCardCost,
  ProjectsCardFeature,
  ProjectsCardFeatures,
  ProjectsCardIcon,
  ProjectsCardInfo,
  ProjectsCardLength,
  ProjectsCardPlan,
  ProjectsContainer,
  ProjectsHeading,
  ProjectsSection,
} from './MoreProjects.elements'

function MoreProjects({
  primary,
  lightBg,
  imgStart,
  lightTopLine,
  lightText,
  headLine,
  lightTextDesc,
  buttonLabel,
  description,
  topLine,
  start,
  img,
  alt,
  buttonLink,
  icon,
  style,
}) {
  return (
    <IconContext.Provider value={{ color: '#a9b3c1', size: '64' }}>
      <ProjectsSection>
        <ProjectsHeading>My Achievements</ProjectsHeading>
        <ProjectsContainer>
          <ProjectsCard to='/projects'>
            <ProjectsCardInfo>
              <ProjectsCardIcon>
                <FaAmazon />
              </ProjectsCardIcon>
              <ProjectsCardPlan>E-Commerce</ProjectsCardPlan>
              <ProjectsCardCost>AMAZON</ProjectsCardCost>
              <ProjectsCardLength>
                Firebase for the Backup-End
              </ProjectsCardLength>
              <ProjectsCardFeatures>
                <ProjectsCardFeature>
                  Created in React Hooks
                </ProjectsCardFeature>
                <ProjectsCardFeature>State Management</ProjectsCardFeature>
                <ProjectsCardFeature>Authentication</ProjectsCardFeature>
                <ProjectsCardFeature>Full Responsive</ProjectsCardFeature>
                <ProjectsCardFeature>Calculate Total Price</ProjectsCardFeature>
                <ProjectsCardFeature>Payments Processing</ProjectsCardFeature>
              </ProjectsCardFeatures>
              <Button>Check Demo</Button>
            </ProjectsCardInfo>
          </ProjectsCard>

          <ProjectsCard to='/projects'>
            <ProjectsCardInfo>
              <ProjectsCardIcon>
                <SiNetflix />
              </ProjectsCardIcon>
              <ProjectsCardPlan>Movies</ProjectsCardPlan>
              <ProjectsCardCost>NETFLIX</ProjectsCardCost>
              <ProjectsCardLength>Axios for the API</ProjectsCardLength>
              <ProjectsCardFeatures>
                <ProjectsCardFeature>
                  Created in React Hooks
                </ProjectsCardFeature>
                <ProjectsCardFeature>Full Responsive</ProjectsCardFeature>
                <ProjectsCardFeature>
                  API Request & Response
                </ProjectsCardFeature>
                <ProjectsCardFeature>
                  Playing Movie's Trailer
                </ProjectsCardFeature>
                <ProjectsCardFeature>
                  Window scrollY Functionality
                </ProjectsCardFeature>
                <ProjectsCardFeature>High Resolution</ProjectsCardFeature>
              </ProjectsCardFeatures>
              <Button>Check Demo</Button>
            </ProjectsCardInfo>
          </ProjectsCard>

          <ProjectsCard to='/projects'>
            <ProjectsCardInfo>
              <ProjectsCardIcon>
                <FaFacebookSquare />
              </ProjectsCardIcon>
              <ProjectsCardPlan>Social Media</ProjectsCardPlan>
              <ProjectsCardCost>FACEBOOK</ProjectsCardCost>
              <ProjectsCardLength>
                Context-React for the State Management
              </ProjectsCardLength>
              <ProjectsCardFeatures>
                <ProjectsCardFeature>
                  Created in React Hooks
                </ProjectsCardFeature>
                <ProjectsCardFeature>
                  Google Account Authentication
                </ProjectsCardFeature>
                <ProjectsCardFeature>
                  Plugin Any Social Media Embed
                </ProjectsCardFeature>
                <ProjectsCardFeature>
                  Create or Delete a Post
                </ProjectsCardFeature>
                <ProjectsCardFeature>
                  Comment Under a Post or Delete it
                </ProjectsCardFeature>
                <ProjectsCardFeature>
                  Exact Icons as Facebook's icons
                </ProjectsCardFeature>
              </ProjectsCardFeatures>
              <Button>Check Demo</Button>
            </ProjectsCardInfo>
          </ProjectsCard>
        </ProjectsContainer>

        <Links to='/projects'>
          <Button big fontBig>
            Check All Achievements
          </Button>
        </Links>
      </ProjectsSection>

      <BreakLine />
    </IconContext.Provider>
  )
}

export default MoreProjects

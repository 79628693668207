import amazon from './images/amazon.svg'
// import amazon from 'url("/Assets/ProjectsImages/Amazon.svg")'
import netflix from './images/netflix.svg'
import youtube from './images/youtube.svg'
import instagram from './images/instagram.svg'
import google from './images/google.svg'
import facebook from './images/facebook.svg'
import todo from './images/todo.svg'
import programming from './images/programming.svg'
import react from './images/react.svg'

import {
  FaAmazon,
  FaFacebookSquare,
  FaInstagramSquare,
  FaReact,
  FaYoutube,
} from 'react-icons/fa'

import { RiNetflixFill } from 'react-icons/ri'
import { FcGoogle, FcTodoList } from 'react-icons/fc'
import { GiRollingEnergy } from 'react-icons/gi'

export const projects = [
  {
    id: '1',
    name: 'Amazon-Clone',
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightText: true,
    headLine: 'mazon Clone',
    lightTextDesc: true,
    buttonLabelVideo: 'Play Demo',
    buttonLabelDetails: 'More Details',
    description:
      'Jeff Bezos might sue me for the copy right!. It looks and works exactly like Amazon real website and accepts payments via stripe. Using Firebase as back-end to save all orders. Your asset will be like his, if you give me a chance!',
    topLine: 'Shopping Time',
    start: '',
    img: amazon,
    // img: 'url("/Assets/ProjectsImages/Amazon.svg")',
    alt: 'Amazon-Clone',
    buttonLink: '/projects',
    icon: <FaAmazon color='#f0c14b' />,
    videoUrl:
      'https://www.youtube.com/watch?v=12K5qPpKp1w&ab_channel=MinuteVideos',
    githubUrl: 'https://github.com/dzirmal/amz-e-commerce-full-clone',
    aboutTheProject: `This is a full responsive e-commerce website. I created this application to prove my ability of creating a full functional e-commerce website. Users are able to create an account using his/her email address. User's information stores in cloud-firebase. Adding products into the cart using quantity or removing from the cart. Switches languages EN to SP. Used React-Context-API for the state management.`,
    packagesUsed:
      'material-ui, material-icons, axios, react-strip-js, strip-js, react-router-dom, styled-components, uuid',
  },
  {
    id: '2',
    name: 'Netflix-Clone',
    lightBg: true,
    primary: false,
    imgStart: 'start',
    lightTopLine: false,
    lightText: false,
    headLine: 'etflix Clone',
    lightTextDesc: false,
    buttonLabelVideo: 'Play Demo',
    buttonLabelDetails: 'More Details',
    description:
      'Movie time is my favorite time, so I created this full responsive website for my self to get the information about the most recently movies. It looks and works exactly like Netflix real website. The efficiency of minimizing helps me Fetches the data from the server in efficient way.',
    topLine: 'Movie Time',
    start: '',
    img: netflix,
    buttonLink: '/projects',
    alt: 'Netflix-Clone',
    icon: <RiNetflixFill color='#ce174c' margin-right='-5px' />,
    videoUrl: 'https://www.youtube.com/watch?v=0DAmWHxeoKw&ab_channel=Netflix',
    style: {
      marginLeft: '-10px',
    },
    aboutTheProject: `This is a full responsive website in any devices. By using react-youtube & movie-trailer dependency, the application is able to play the trailer.`,
    packagesUsed:
      'material-ui, material-icons, axios, react-dom, strip-js, react-scripts, styled-components, react-youtube',
  },
  {
    id: '3',
    name: 'You Tube-Clone',
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightText: true,
    headLine: 'YouTube Clone',
    lightTextDesc: true,
    buttonLabelVideo: 'Play Demo',
    buttonLabelDetails: 'More Details',
    description:
      'You Tube is one of the most papular website that requires high skill web designing to leave a great User Experience for its users. By giving myself a challenge and using my high quality skills I am able to create this beauty!',
    topLine: 'Video Time',
    start: 'true',
    img: youtube,
    buttonLink: '/projects',
    alt: 'You Tube-Clone',
    icon: <FaYoutube color='#ce174c' />,
    videoUrl:
      'https://www.youtube.com/watch?v=1RrbIOwnLTA&ab_channel=SHEKIBAZIZ',
    aboutTheProject: `This is a full responsive website in any devices. It is a only YouTube Home page.`,
    packagesUsed:
      'material-ui, material-icons, axios, react-dom, react-router-dom, react-scripts, styled-components,',
  },
  {
    id: '4',
    name: 'Facebook-Clone',
    lightBg: true,
    primary: false,
    imgStart: 'start',
    lightTopLine: false,
    lightText: false,
    headLine: 'acebook Clone',
    lightTextDesc: false,
    buttonLabelVideo: 'Play Demo',
    buttonLabelDetails: 'More Details',
    description:
      'Everyone is in social media. My skills in React are enough to create a ginormous website like Facebook. In this website a user is able to sign-in/sign-up with his/her google account and email address if the user prefers. The user can post about anything and any other users can leave comments under the post. The owner of account can delete the post or delete the comments under the post. Thanks to the Facebook team for supporting React!',
    topLine: 'Social Time',
    start: '',
    img: facebook,
    buttonLink: '/projects',
    alt: 'Facebook-Clone',
    icon: <FaFacebookSquare color='#2e81f4' />,
    videoUrl:
      'https://www.youtube.com/watch?v=StPGR1URK5A&ab_channel=ContentMarketing',
    aboutTheProject: `This is a full responsive website in any devices. It is a only Facebook Home page.`,
    packagesUsed:
      'material-ui, material-icons, axios, react-dom, react-router-dom, react-scripts, styled-components,',
  },
  {
    id: '5',
    name: 'Instagram-Clone',
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightText: true,
    headLine: 'Instagram Clone',
    lightTextDesc: true,
    buttonLabelVideo: 'Play Demo',
    buttonLabelDetails: 'More Details',
    description: 'Does She like hooks in React? #reactHooks',
    topLine: `Pictures Time`,
    start: 'true',
    img: instagram,
    buttonLink: '/projects',
    alt: 'Instagram-Clone',
    icon: <FaInstagramSquare color='#F77737' />,
    videoUrl:
      'https://www.youtube.com/watch?v=cum7p51wcPc&ab_channel=kkaytechnologies',
    aboutTheProject: `This is a full responsive website in any devices. It is a only Instagram Home page.`,
    packagesUsed:
      'material-ui, material-icons, axios, react-dom, react-router-dom, react-scripts, styled-components,',
  },
  {
    id: '6',
    name: 'Google-Clone',
    lightBg: true,
    primary: false,
    imgStart: 'start',
    lightTopLine: false,
    lightText: false,
    headLine: 'oogle Clone',
    lightTextDesc: false,
    buttonLabelVideo: 'Play Demo',
    buttonLabelDetails: 'More Details',
    description: `I'm searching everything about React in my own website using Google API search engin!`,
    topLine: 'Search Time',
    start: '',
    img: google,
    buttonLink: '/projects',
    alt: 'Google-Clone',
    icon: <FcGoogle />,
    videoUrl:
      'https://www.youtube.com/watch?v=c1Sb6ByMqHw&ab_channel=RAJENDRAPHADTARE',
    aboutTheProject: `This is a full responsive website in any devices. It is a only Google Home page which connected with the google search engine.`,
    packagesUsed:
      'material-ui, material-icons, axios, react-dom, react-router-dom, react-scripts, styled-components,',
  },
  {
    id: '7',
    name: 'Todo List',
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightText: true,
    headLine: 'Todo List',
    lightTextDesc: true,
    buttonLabelVideo: 'Play Demo',
    buttonLabelDetails: 'More Details',
    description:
      'For helping my punctuality, I created this monster! So that is why I always on time! God of web development is React!',
    topLine: `Done Time`,
    start: 'true',
    img: todo,
    buttonLink: '/projects',
    alt: 'Todo List',
    icon: <FcTodoList />,
    videoUrl: 'https://www.youtube.com/watch?v=ibGnjSKLqTc&ab_channel=TheMuse',
    aboutTheProject: `This is a full responsive app in any devices. It is a basic Todo List with adding, removing and updating daily todos.`,
    packagesUsed:
      'material-ui, material-icons,  react-scripts, styled-components,',
  },
]

export const homePageObjects = [
  {
    id: '8',
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightText: true,
    headLine: `I'm A Front-End Engineer with Endless Energy`,
    lightTextDesc: true,
    buttonLabel: 'Check My Achievements',
    description:
      'I help business owners increase their revenue. My Knowledge of unique technics leads me to help you achieve your business goals as well!',
    topLine: 'Front-End Website Developer',
    start: '',
    img: programming,
    alt: 'Image',
    buttonLink: '/projects',
    icon: <GiRollingEnergy color='#ffefa0' />,
  },

  {
    id: '9',
    lightBg: true,
    primary: false,
    imgStart: 'start',
    lightTopLine: false,
    lightText: false,
    headLine: 'React is FIRRRRE!!!!!!',
    lightTextDesc: true,
    buttonLabel: 'Check My Resume',
    description: 'And I know how to use this fire!',
    topLine: 'React',
    start: '',
    img: react,
    alt: 'Image',
    buttonLink: '/resume',
    icon: <FaReact color='#00adb5' />,
  },
]

import journeyStarted from '../../images/aboutMeImg/journeyStarted.svg'
import careerProgress from '../../images/aboutMeImg/careerProgress.svg'

import { FaChild } from 'react-icons/fa'
import { GiJourney } from 'react-icons/gi'

export const aboutMe = [
  {
    lightBg: false,
    primary: true,
    imgStart: '',
    lightTopLine: true,
    lightText: true,
    headLine: 'Career Progress',
    lightTextDesc: true,
    buttonLabel: 'Contact Me',
    description:
      'When I was eight-years-old the tough "time" enforced my parents to let me go far from home and started selling cigaret on the street instead of playing with toys at home.',
    topLine: 'ChildHood',
    start: '',
    img: careerProgress,
    alt: 'ChildHood',
    buttonLink: '/projects',
    icon: <FaChild color='#0BD5EA' />,
  },
  {
    lightBg: true,
    primary: false,
    imgStart: 'start',
    lightTopLine: false,
    lightText: false,
    headLine: 'Journey',
    lightTextDesc: false,
    buttonLabel: 'Contact Me',
    description:
      'After two years  My Journey started when I was 10-years-old. ',
    topLine: 'JourneyHood',
    start: '',
    img: journeyStarted,
    buttonLink: '/contacts',
    alt: 'Image',
    icon: <GiJourney color='#00adb5' />,
  },
]

import styled from 'styled-components'
import { motion } from 'framer-motion'

export const ResumeContainer = styled(motion.div)`
  display: flex;
  margin: 50px auto;
  max-width: 1400px;
  border: 1px solid lightgray;
  padding: 20px;
  /* background-color: #101522; */

  @media print {
    @page {
      size: auto;
      margin: 20mm;
      margin-top: 4mm;
    }
  }
`

export const ResumeLeftContents = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
`

export const Name = styled.p`
  /* color: #0bd5ea; */
  /* color: #364f6b; */
  /* color: #00adb5; */
  margin: 5px;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
    font-size: 1rem;
    margin: 2px;
  }
`

export const FrontEndDeveloper = styled.p`
  /* color: #0bd5ea; */
  font-size: 2rem;
  font-weight: bold;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
    font-size: 1rem;
    margin: 2px;
  }
`

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 400;

  @media print {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-weight: 300;
  }
`

export const Contacts = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media print {
    display: flex;
    flex-direction: column;
  }
`

export const Contact = styled.p`
  display: flex;
  margin: 5px;
  &::before {
    content: '|';
    /* color: #0bd5ea; */
    font-weight: 500;
    margin-right: 10px;
  }

  @media print {
    margin: 2px;
    &::before {
      content: none;
    }
  }
`

export const AboutMeContainer = styled.div``

export const ResumeRightContents = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
`

export const Title = styled.div`
  /* color: #0bd5ea; */
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 2rem;
  font-weight: bold;

  @media print {
    font-size: 1rem;
    margin: 1px;
  }
`

export const Explanation = styled.div`
  font-size: 1.3rem;
  font-weight: 400;
  color: #000;
  margin-left: 40px;
  padding: 5px;
  &::before {
    content: '⋄';
    margin-right: 10px;
  }

  @media print {
    font-size: 1rem;
    margin: 0.5px;
  }
`

export const SubTitle = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 30px;
  font-size: 1.3rem;
  font-weight: bold;

  &::before {
    content: '•';
    margin-right: 10px;
  }

  @media print {
    font-size: 1rem;
    margin: 1px;

    &::before {
      content: '•';
      margin-right: 5px;
    }
  }
`

export const Skills = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
`

export const Skill = styled.p`
  display: flex;
  font-size: 1.3rem;
  font-weight: 400;
  margin-top: 10px;

  &::after {
    content: '|';
    margin-right: 5px;
    margin-left: 5px;
  }

  @media print {
    font-size: 1rem;
    margin: 2px;
    &::after {
      content: '|';
      margin-right: 2px;
      margin-left: 2px;
    }
  }
`

export const ExperienceSkill = styled.p`
  display: flex;
  font-size: 1.3rem;
  font-weight: 400;
  margin-top: 10px;

  @media print {
    font-size: 1rem;
    margin: 5px;
  }
`

export const Experience = styled.div`
  display: flex;
  margin-left: 20px;
  align-items: center;

  @media print {
    font-size: 1rem;
    margin: 1px;
  }
`

export const PersonalProjectsContainer = styled.div`
  display: flex;
  width: 100%;

  @media print {
    font-size: 1rem;
    margin: 1px;
  }
`

export const PersonalProjectsCompleted = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px;

  @media print {
    font-size: 1rem;
    margin: 1px;
  }
`

export const PersonalProjectsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;

  @media print {
    margin: 1px;
  }
`

export const PersonalProjectsSubtitle = styled.p`
  margin: 5px;

  &::before {
    content: '•';
    margin-right: 6px;
  }
  &::after {
    content: ':';
    margin-left: 3px;
  }

  @media print {
    font-size: 1rem;
    margin: 1px;

    &::before {
      content: '•';
      margin-right: 2px;
    }
    &::after {
      content: ':';
      margin-left: 1px;
    }
  }
`

export const PersonalProjects = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;

  @media print {
    margin: 1px;
  }
`

export const PersonalProject = styled.p`
margin-left: 5px;

  @media print {
    margin: 1px;
  }
  }
`

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`

export const Link = styled.a`
  margin-left: 50px;
`

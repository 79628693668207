import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import { devices } from '../../device'
import { motion } from 'framer-motion'

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 100;
`

export const ModalWrapper = styled(motion.div)`
  box-shadow: 0 5xp 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  height: 80vh;
  margin: 100px;
  position: relative;
  z-index: 101;
  border-radius: 10px;

  @media ${devices.tabletM} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  width: 100%;
  height: 100%;

  @media ${devices.tabletM} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const ReactPlayerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80%;
  padding: 10px;
`

export const CloseModalIcon = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 101;
`

export const DetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 25px;
`

export const ProjectName = styled.h1`
  font-size: 40px;
  font-weight: bold;

  @media ${devices.tabletM} {
    font-size: 30px;
  }
`

export const AboutTheProject = styled.h2`
  font-size: 30px;
  @media ${devices.tabletM} {
    font-size: 15px;
  }
`
export const AboutTheProjectDetails = styled.h2`
  font-size: 30px;
  @media ${devices.tabletM} {
    font-size: 12px;
  }
`
export const Dependencies = styled.h2`
  font-size: 30px;
  font-weight: bold;
  @media ${devices.tabletM} {
    font-size: 15px;
    font-weight: bold;
  }
`
export const PackageName = styled.h2`
  font-size: 30px;
  @media ${devices.tabletM} {
    font-size: 15px;
  }
`

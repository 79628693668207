import styled from 'styled-components'
import { Button } from '../../globalStyles'

export const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(234, 237, 237);
`

export const H1 = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500px;
  margin-bottom: 20px;
`

export const FormIcon = styled.span`
  margin-right: 10px;
`

export const Label = styled.label`
  padding-bottom: 10px;
  color: rgb(0, 0, 32);
  font-weight: bold;
`

export const ContactFormDiv = styled.div`
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #fff;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const Input = styled.input`
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137);
  margin-bottom: 20px;
  border: 1px solid lightgray;
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
  &::placeholder {
    color: lightgray;
  }
`

export const TextArea = styled.textarea`
  height: 150px;
  max-width: 400px;
  min-height: 100px;
  margin-bottom: 24px;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137);
  margin-bottom: 20px;
  border: 1px solid lightgray;
  background: #fff;
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
  &::placeholder {
    color: lightgray;
  }
`

export const FormButton = styled(Button)`
  background: ${({ loader }) => (loader ? '#ccc' : '#00adb5')};
`

export const ButtonIcon = styled.span`
  font-size: 16px;
  margin-right: 5px;
`

import React, { useState } from 'react'
import { IconContext } from 'react-icons/lib'
import { FaBars, FaTimes, FaAngleDown } from 'react-icons/fa'

import { Dropdown } from '../index'

import {
  NavIcon,
  Nav,
  NavItem,
  NavMenu,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavLinks,
  MyInit,
  MyName,
} from './Header.elements'

function Header() {
  const [click, setClick] = useState(false)
  const [dropdown, setDropdown] = useState(false)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false)
    } else {
      setDropdown(true)
    }
  }
  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false)
    } else {
      setDropdown(false)
    }
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to='/' onClick={closeMobileMenu}>
              <NavIcon />
              <MyName>ZRRAWAR DZIRMAL</MyName>
              <MyInit>ZD</MyInit>
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem>
                <NavLinks to='/'>Home</NavLinks>
              </NavItem>

              <NavItem onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <NavLinks to='/projects' onClick={() => closeMobileMenu()}>
                  Projects <FaAngleDown />
                </NavLinks>
                {dropdown && <Dropdown />}
              </NavItem>

              <NavItem>
                <NavLinks to='/about' onClick={() => closeMobileMenu()}>
                  About Me
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to='/contacts' onClick={() => closeMobileMenu()}>
                  Contacts
                </NavLinks>
              </NavItem>
              {/* <NavItem>
                <NavLinks to='/resume' onClick={() => closeMobileMenu()}>
                  Resume
                </NavLinks>
              </NavItem> */}
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  )
}

export default Header
